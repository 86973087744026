.anchor-div {
    margin: auto;
    /* height: 18px; */
    font-size: 13px;
    line-height: 16px;
    padding-right: 20px;
    font-weight: 400;
}

.img-div {
    width: 16px;
    height: 16px;
    background-size: cover;
}

.point-to-location-div {
    float: left;
}

.time {
    color: gray;
}

@media only screen and (max-width: 600px) {
    body .img-div {
        width: 16px;
        height: 16px;
        background-size: cover;
    }
  
}

.title-author {
    font-size: 13px;
    padding: 0px 6px 2px 26px;
    color: #1f1f1f;
    font-weight: normal;
}

 .position-left {
    position: absolute;
    left: 0;
    margin: 5px;
}
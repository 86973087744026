/*   
@media only screen and (max-width: 600px) {
    div .modal-dialog.modal-lg {
        width: calc(100vw - 200px) ;
        max-width: calc(100vw - 200px);
      }
} */

body .modal-content {
    background: black;
    color: #fff;
}

@media only screen and (min-width: 1200px) {
    div .modal-dialog.modal-lg {
        width: 1200px;
        max-width: 1200px;
    }
    .modal.show .modal-dialog {
        min-width: 675px;
    }
}

@media only screen and (max-width: 600px) {
    body .modal-body {
        padding: 5px;
    }
}
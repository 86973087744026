.hash-tag.btn-outline-danger {
    color: #feffff;
    margin: 5px;
    border-color: #db0000;
    padding: 4px 6px;
    font-size: 14px;
    box-shadow: 1px 1px 2px 2px #00000024;
    background: #db0000;
}

.hash-tag.btn-outline-danger:hover {
    color: #fff;
}

.hash-tag .badge-secondary {
    color: #fff;
    background-color: #a60303;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #6d221c;
    border-color: #7b151f;
}


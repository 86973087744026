body {
    overflow: hidden;
}

body h1 {
    font-size: 24px;
    margin: 0 10px;
    color: #636363;
}

td {
    vertical-align: top;
}

body h2 {
    /* font-size: 16px;
    margin: 0 10px;
    font-weight: bold; */
    font-size: 17px;
    font-weight: 600;
    color: #090909;
}

.navbar {
    background: #ba0000;
}

.logo-title {
    display: inline-block;
    color: #FFF;
    font-weight: bold;
    width: 56px;
}

.logo-subtitle {
    width: 380px;
    height: 25px;
    display: inline-flex;
    overflow: hidden;
    color: #FFF;
    font-size: 17px;
}

.inline-block {
    display: inline-block;
}

.App {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    direction: rtl;
    text-align: right;
}

.videos-container {
    overflow-x: hidden;
    overflow-y: auto;
}

.img-div.mako {
    background-image: url(./assets/mako64.png);
}

.img-div.walla {
    background-image: url(./assets/walla64.png);
}

.img-div.ynet {
    background-image: url(./assets/ynet64.png);
}

.img-div.channel20 {
    background-image: url(./assets/channel2064.png);
}

.img-div.news0404 {
    background-image: url(./assets/news040464.png);
}

.img-div.channel7 {
    background-image: url(./assets/channel764.png);
}

.img-div.twitter {
    background-image: url(./assets/twitter64.png);
}

.img-div.telegram {
    background-image: url(./assets/telegram64.png);
}

.img-div.kan {
    background-image: url(./assets/kan64.png);
}

.img-div.israelToday {
    background-image: url(./assets/israelToday64.png);
}

.img-div.maariv {
    background-image: url(./assets/maariv64.png);
}

.telegram-icon {
    background-image: url(./assets/s_telegram64.png);
}

.whatsup-icon {
    background-image: url(./assets/s_whatsup64.png);
}

.facebook-icon {
    background-image: url(./assets/s_facebook64.png);
}

.social-icon:hover {
    opacity: 1;
}

.social-icon {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-size: cover;
    opacity: 0.8;
    float: left;
}

.list-group {
    padding: 0;
}

li.list-group-item {
    padding: 13px;
    font-size: 15px;
}

li.list-group-item:hover {
    background-color: #f3f3f3;
}

.div1 {
    width: calc(100% - 900px);
    height: 100%;
    left: 0;
    position: absolute;
}

.right-div {
    width: 450px;
    position: absolute;
    bottom: 0;
    top: 57px;
}

.feed-card {
    padding: 12px;
}

table {
    width: 100%;
    table-layout: fixed;
}

th {
    background: #0095ff;
    color: white;
}

.right-div-title {
    /* background: #db0000; */
    /* color: rgb(14, 14, 14); */
    height: 31px;
    padding: 3px 8px;
    margin: 3px 0px -8px 0px;
}

.mivzakim-div-title {
    /* background: #002763; */
    /* color: rgb(14, 14, 14); */
    height: 31px;
    padding: 3px 8px;
}

.floating-box {
    display: inline-block;
    vertical-align: top;
    /* margin: 1px 0px 5px 5px; */
    overflow: hidden;
    background: white;
    /* box-shadow: 1px 1px 1px 1px #0000001c; */
    margin: 5px 0px 10px 0px;
    box-shadow: 1px 1px 2px 2px #00000008;
}

.floating-box:not(.expanded):hover {
    cursor: pointer;
    box-shadow: 1px 1px 2px 2px #00000010;
}

.youtube-floating-box {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    overflow: hidden;
    width: calc(50% - 8px);
    /* height: 175px; */
    margin: 5px 4px;
    height: 110px;
}

div.card-columns {
    column-count: 1;
    padding: 8px;
}

.left-div {
    width: 450px;
    position: absolute;
    bottom: 0;
    top: 57px;
    left: 0;
    direction: ltr;
    text-align: left;
}

.quick-search-label {
    padding: 5px 5px;
}

.nav-style {
    border-bottom: 1px solid #cecece;
}

a.nav-link {
    color: white
}

div.custom-switch {
    margin: 8px;
}

.mobile-tabs-container {
    position: fixed;
    width: 100%;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .11);
    padding: 0;
    /* border-bottom: 1px solid #d7d7d7; */
    z-index: 10;
    border-top: 2px solid #d50000;
    background: #eee;
}

.mobile-header-tab {
    width: 25%;
    display: inline-block;
    padding: 8px 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #252525;
}

.mobile-header-tab.active {
    border-bottom: 4px solid #db0000;
    color: #db0000;
}

/* body div a.mobile-tab.active {
    color: #ffffff;
    border-bottom: 4px solid #CF0000;
} */

body div a.mobile-tab {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    color: #6c6c6c;
}

#titlesContainer {
    /* margin-bottom: 8px;
    background: white;
    border-bottom: 1px solid #dadada; */
    background: #eeeeee;
}

@media only screen and (min-width: 600px) {
    #blueNav {
        background: #911414;
        width: calc(50% - 200px);
        height: 56px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    #blueNavTriangle {
        width: 0;
        height: 0;
        border-bottom: 100px solid #911414;
        border-right: 200px solid transparent;
        margin: 0px -200px 0px 0px;
    }
}

@media only screen and (max-width: 600px) {
    body {
        overflow: auto;
    }
    /* #browserTopWrapper {
        display: none;
    } */
    .App {
        background: #eeeeee;
    }
    body h1 {
        font-size: 18px;
        color: #ffffff;
    }
    body h2 {
        margin: 5px 0px;
        color: #646464;
    }
    .logo-subtitle {
        font-size: 15px;
    }
    .videos-container {
        height: auto;
        overflow: auto;
        overflow-x: hidden;
    }
    body .logo-title {
        color: white;
    }
    #mobileBodyContainer {
        margin-top: 84px;
    }
    body .floating-box {
        width: 100%;
        height: auto;
        /* height: 34vw; */
        font-weight: normal;
        font-size: 15px;
        margin: 10px 0px 0px 0px;
    }
    body .news-title-min-div {
        height: 34vw;
    }
    body .youtube-floating-box {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        margin: 3px 0px;
        overflow: hidden;
        height: 28vw;
        background: white;
        box-shadow: 1px 1px 2px 2px #00000003;
    }
    body .navbar {
        padding: 0px;
        position: fixed;
        width: 100%;
        top: 0;
        height: 38px;
        z-index: 12;
        background: #db0000;
    }
    body .navbar-brand {
        padding-top: 3px;
        margin-right: 9px;
        position: absolute;
        top: 0;
    }
    body .logo-subtitle {
        width: 275px;
        color: #ffffff;
    }
    body .div1 {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        bottom: 0;
    }
    body .left-div {
        display: none;
    }
    body .right-div {
        width: 100%;
        position: absolute;
        top: 57px;
        height: auto;
        bottom: 0%;
        overflow: auto;
    }
    .root {
        height: 100vh;
    }
    body #titlesDiv {
        padding: 0px 5px 8px 5px;
        height: auto;
    }
    li.list-group-item {
        padding: 10px;
    }
    .right-div-title {
        background: #eeeeee;
    }
}

#titlesDiv {
    padding: 5px;
    overflow-x: hidden;
    /* height: calc(100vh - 57px); */
}

iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
}

#rightSearch {
    margin: 7px 4px;
    width: calc(100% - 10px);
    background: url(./assets/search.png) no-repeat scroll 7px 7px;
    padding-left: 30px;
}

.title-anchor {
    color: #1d1d1d;
}

#hashTagsContainer {
    font-size: 16px;
}

#videosContainer {
    overflow: auto;
    overflow-x: hidden;
}

#mivzakimSearchDiv {
    position: relative;
}

.video-description-div {
    float: left;
    width: 50%;
    padding: 6px 8px;
}

.browser-view-wrapper {
    width: 750px;
    margin: auto;
}

#browserTitlesViewWrapper {
    width: 500px;
}

#browserMivzakimViewWrapper {
    position: fixed;
    top: 60px;
    left: calc(50% - 500px);
    width: 360px;
}

#browserTopWrapper {
    overflow: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 56px;
    background: #eeeeee;
}

#weatherWrapper {
    width: 360px;
    margin-top: 15px;
    box-shadow: 1px 1px 2px 2px #00000005;
}

#browserRightViewWrapper {
    position: absolute;
    top: 0px;
    left: calc(50% + 400px);
    width: 200px;
    margin-top: 30px;
}

.card-box {
    background: #FFF;
    box-shadow: 1px 1px 2px 2px #00000005;
    padding: 5px 6px;
}

.custom-checkbox {
    margin-right: 25px;
    
}
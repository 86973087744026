/* width */

.cyber ::-webkit-scrollbar {
    width: 10px;
}

/* Track */

.cyber ::-webkit-scrollbar-track {
    background: #0b1e31;
}

/* Handle */

.cyber ::-webkit-scrollbar-thumb {
    background: rgb(16, 173, 100);
}

/* Handle on hover */

.cyber ::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 141, 25);
}

/* width */

::-webkit-scrollbar {
    width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #e4e4e4;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: rgb(204, 204, 204);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: rgb(177, 177, 177);
}
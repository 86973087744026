.floating-box {
    transition: max-height 0.15s ease-out;
}

@media only screen and (max-width: 600px) {
    body .thumbnail-image {
        /* float: inherit; */
        height: 34vw;
    }
    body .gallery-image {
        height: 60vw;
        width: 100%;
    }
    /* body .news-title-wrapper-div.expanded {
        width: 100%;
    } */
}

/* @media only screen and (min-width: 600px) {
    .title-description-div {
        min-height: 170px;
        width: 295px;
    }
} */

.share-div-icon {
    text-align: center;
    padding: 3px 0px 3px 0px;
    width: 45px;
    display: inline-block;
    vertical-align: middle;
}

.share-div-icons-container {
    width: 60%;
    display: inline-block;
    text-align: left;
}

.news-title-external-link-div {
    width: 40%;
    padding: 10px 5px 3px 0px;
    display: inline-block;
    font-weight: 600;
    color: #9b9b9b;
    font-size: 16px;
}

.news-title-extended-div {
    padding: 10px;
}

.news-title-share-div {
    border-top: 1px solid #dddddd;
    margin-top: 7px;
}

.news-title-wrapper-div {
    color: #1e1e1e;
    font-size: 16px;
    overflow: hidden;
    padding: 5px 7px;
    float: right;
    line-height: 1.4;
    width: 50%;
    font-weight: 600;
}

.news-title-wrapper-div.expanded {
    width: 100%;
}

.video-image-container {
    height: auto;
    float: left;
    width: 50%;
}


@media only screen and (max-width: 600px) {
    body .video-image-container {
        height: 34vw;
    }
}


.thumbnail-image {
    width: 50%;
    float: left;
    height: 175px;
    background-size: cover;
    background-position: center;
}

.gallery-image {
    /* width: 50%;
    height: 175px; */
    height: 300px;
    width: 100%;
    float: left;
    background-size: cover;
    background-position: center;
}

.donwload-app-ad {
    border: 1px solid #cdcdcd;
    margin: 9px;
    padding: 7px;
    border-radius: 3px;
    box-shadow: 1px 1px 2px 2px #3030301a;
    font-weight: bold;
    color: gray;
}

.gallery-images-count {
    width: 23px;
    height: 24px;
    background: #00000070;
    color: white;
    font-weight: 400;
}

.image-gallery {
    display: inline-block;
}

.image-gallery-image {
    max-height: 300px;
}
#mivzakimContainer {
    background: #FFF;
    /* border-top: 1px solid #dadada; */
}

#clearSearch {
    color: #fff;
    background-color: #272a2c;
    border-color: #6c757d;
    padding: 4px 10px;
    margin: 2px 8px;
    position: absolute;
    top: 0;
    left: 0;
}

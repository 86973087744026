.overlay {
  /* position: relative;
    height: 110px; */
  /* width: 50%; */
  position: relative;
}

.overlay .thumbnail {
  display: block;
  width: 100%;
  height: 34vw;
  background-size: cover;
  background-position: center;
}

@media only screen and (min-width: 600px) {
  div.overlay .thumbnail {
    height: 91px;
  }
  div.overlay .playWrapper {
    position: absolute;
    width: 158px;
    height: 91px;
  }
}

.overlay .video-lenght {
  position: relative;
  z-index: 2;
  bottom: 23px;
  width: max-content;
  font-size: 13px;
  padding: 2px 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

.overlay .video-views {
  z-index: 2;
  width: max-content;
  float: left;
  font-size: 13px;
  padding: 2px 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  clear: both;
  margin-top: -46px;
  position: relative;
}

.overlay .playWrapper {
  opacity: 1;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 34vw;
  background: rgba(0, 0, 0, 0.6) url("http://wptf.com/wp-content/uploads/2014/05/play-button.png") no-repeat scroll center center / 50px 50px;
}

.playWrapper .playBtn {
  position: absolute;
  z-index: 2;
  width: 50px;
  height: 50px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  /* center */
}

.overlay:hover .playWrapper {
  opacity: 1;
}